import { COLORS } from '@global/styles/colors';
import { TABLET_600 } from '@global/styles/sizes';
import styled from 'styled-components';

export const CalendarContainer = styled.div`
  width: 100%;
  height: 100%;
  margin: 50px 0 60px;
  box-sizing: border-box;
  table,
  a,
  td,
  th {
    font-family: 'Poppins' !important;
    font-size: 14px;
    line-height: 21px;
    color: #252a34;
  }
  .fc-daygrid-event-dot {
    width: 15px;
    height: 5px;
    border-radius: 10px;
    background-color: ${COLORS.violetRed};
    border: none;
  }
  .fc-event-time {
    font-family: 'Poppins' !important;
    font-size: 10px;
    line-height: 16px;
    color: ${COLORS.resolutionBlue};
  }
  .fc-event-title {
    text-transform: capitalize;
    font-family: 'Poppins' !important;
    font-weight: bold;
    font-size: 10px;
    line-height: 16px;
    color: ${COLORS.resolutionBlue};
  }
  .fc .fc-toolbar {
    @media (max-width: ${TABLET_600}px) {
      flex-flow: wrap;
      gap: 10px;
    }
  }
  .fc-button-primary,
  .fc-button-active {
    width: 59px;
    height: 35px;
    background-color: #e2e8f0;
    border-color: #e2e8f0;
    color: #252a34;
    font-size: 12px;
    line-height: 21px;
    font-family: 'Poppins';
    box-shadow: none !important;
    border: none !important;
    text-transform: capitalize;

    :focus {
      background-color: #86c5dc;
    }
    &:active {
      background-color: #0e8cb9 !important;
    }

    &:hover {
      background-color: #86c5dc;
    }
  }
  .fc-toolbar-title {
    font-size: 24px;
    line-height: 35px;
    font-family: 'Poppins';
    font-weight: bold;
  }

  .fc .fc-button-primary:not(:disabled).fc-button-active,
  .fc .fc-button-primary:not(:disabled):active {
    background-color: #0e8cb9 !important;
  }
  .fc .fc-daygrid-day.fc-day-today {
    background-color: #f2f7fb;
  }
  .fc-next-button,
  .fc-prev-button {
    width: 41px;
  }
`;

export const BackContainer = styled.div`
  margin-top: 30px;
`;
