import React from 'react';
import Head from '@components/Head';

import { LayoutContainer } from '@components/Layout';
import { WelcomePromoBar } from '@global/data/promobarsData';
import { graphql } from 'gatsby';
import FullCalendarComponent from '@components/FullCalendarComponent/FullCalendarComponent';

const Calendar = ({ data }: any) => {
  const landingLayoutData = data?.cms?.landing?.data?.attributes?.Layout;

  return (
    <main>
      <Head
        title="Your College & Career Readiness Journey | Pathful"
        description="Pathful is the complete college and career readiness system that helps students decide what they want to be and how to prepare for life beyond the classroom."
        tag="classroom, career, internship, learning, curriculum, career exploration, discovery, planning, path, work-based learning, career readiness, education outreach, real-world learning, course planner, graduation plans, life skills, digital lessons, virtual lessons, virtual job shadow, job shadow, job shadowing, career videos, career technical education, career placement, K12 internship opportunities"
        url="https://pathful.com/"
        imageUrl="https://pathful.com/pathful_preview.png"
      />
      <LayoutContainer
        promobarConfig={WelcomePromoBar}
        activeLogo={landingLayoutData?.Topbar_Scroll_Logo}
        topbarBorderColor={landingLayoutData?.Topbar_Details_Color}
        topbarMenuId={landingLayoutData?.Topbar_Menu}
        footerMenuId={landingLayoutData?.Footer_Menu}
        floatingTopbar
      >
        <FullCalendarComponent />
      </LayoutContainer>
    </main>
  );
};

export const pageQuery = graphql`
  query ($id: ID!) {
    cms {
      landing(id: $id) {
        data {
          attributes {
            Layout {
              Topbar_Details_Color
              Topbar_Scroll_Logo {
                data {
                  id
                  attributes {
                    url
                  }
                }
              }
              Topbar_Menu
              Footer_Menu
            }
          }
        }
      }
    }
  }
`;

export default Calendar;
